import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{ path: 'login', loadChildren: () => import('./View/Login/LoginViewModule').then(m => m.LoginViewModule) },
	{ path: '', loadChildren: () => import('./View/Main/AppViewModule').then((m) => m.AppViewModule) },
  { path: 'pcrform', loadChildren: () => import('./View/Main/Display/User/PcrInterview/PcrInterviewModule').then(m => m.PcrInterviewModule)},
	{ path: 'reserve', loadChildren: () => import('./View/Main/Display/User/Reserve/ReserveCreateForm/ReserveCreateFormModule').then(m => m.ReserveCreateFormModule)},
	{ path: 'viewer', loadChildren: () => import('./View/PDFViewer/PDFViewerModule').then((m) => m.PDFViewerModule) },
  { path: 'sas', loadChildren: () => import('./View/Main/Display/User/Sas/sas.module').then(m => m.SasModule)},
	{ path: 'payment', loadChildren: () => import('./View/Main/Display/User/ExamAfterProcess/ExamAfterProcessModule').then(m => m.ExamAfterProcessModule)},
	{ path: 'viewer', loadChildren: () => import('./View/PDFViewer/PDFViewerModule').then((m) => m.PDFViewerModule) },
	{ path: 'online-agreement', loadChildren: () => import('./View/Main/Display/User/ReserveOnlineInsureAgreement/ReserveOnlineInsureAgreementModule').then((m) => m.ReserveOnlineInsureAgreementModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
